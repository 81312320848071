<template>
  <div class="mt-20 home-container">
    <div v-if="canUpdateThreshold" class="relative text-sm">
      <div class="flex absolute right-0 bottom-0">
        <input v-model="threshold" type="number" placeholder="from 0.00 to 1.00" class="w-16 p-2" />
        <div class="ml-2">
          <div class="font-600">search threshold</div>
          <div class="text-xs">0.0 requires a perfect match, a threshold of 1.0 would match anything.</div>
        </div>
      </div>
    </div>
    <div class="greeting" :class="{ 'no-go': searchIsFocused || query !== '' }">
      good {{ greeting }}, {{ profile.firstName.toLowerCase() }}
    </div>
    <label
      for="mainSearch"
      class="search-label"
      :class="{ 'move-it': searchIsFocused || query.length }"
      >what are you looking for?</label
    >
    <div class="relative">
      <input
        type="text"
        class="homepage-search-input pr-8"
        id="mainSearch"
        v-model="query"
        @click="searchIsFocused = true"
        @blur="searchIsFocused = false"
        autocomplete="off"
        ref="searchInput"
      />
      <span class="clear-search" v-if="query.length" @click="setFocus()">
        <icon name="close" class="w-4 h-4 text-gray-400 cursor-pointer"></icon>
      </span>
    </div>

    <div v-if="isSearching" class="flex flex-wrap mt-8">
      <div
        v-for="result in searchResults"
        :key="result.item.name"
        class="w-1/3 mb-6 flex items-center"
      >
        <span
          class="inline-block w-2 h-2 mr-2 bg-black"
          :class="result.item.meta.product"
        ></span>
        <modal-link
          :route="result.item.meta.flowStartsWith"
          :params="{ passFlowTo: getPassFlow(result), ...getParams(result) }"
          class="font-frank lowercase font-400"
        >
          <span v-html="highlightSearch(result)"/>
        </modal-link>
      </div>
    </div>

    <homepage-bottom-toolbar></homepage-bottom-toolbar>
  </div>
</template>

<script>
import ModalLink from "@/components/ui/modals/ModalLink";
import Icon from "@/components/ui/Icon";
import AuthMixin from "@/components/auth/AuthMixin";
import HomepageBottomToolbar from "@/components/layout/HomepageBottomToolbar";
import { mapGetters } from "vuex";
import Fuse from "fuse.js";
import { debounce, intersection } from "lodash-es";
import moment from "moment";

const fuseOptions = {
  keys: [
    {
      name: "meta.title",
      weight: 0.7,
    },
    {
      name: "name",
      weight: 0.2,
    },
    {
      name: "path",
      weight: 0.1,
    },
  ],
  threshold: 0.4,
  includeMatches: true,
  minMatchCharLength: 3,
};

export default {
  components: { HomepageBottomToolbar, ModalLink, Icon },
  mixins: [AuthMixin],
  data() {
    return {
      query: "",
      searchIsFocused: false,
      searchResults: [],
      fuse: null,
      greeting: "morning",
      threshold: 0.4,
    };
  },
  computed: {
    ...mapGetters({
      allSearchableRoutes: "search_routes/getSearchableRoutes",
    }),
    isSearching() {
      return this.query || this.searchIsFocused;
    },
    canUpdateThreshold() {
      return +process.env.VUE_APP_SEARCH_THRESHOLD;
    }
  },
  mounted() {
    this.fuse = new Fuse(this.allSearchableRoutes, fuseOptions);
    this.checkTimeOfDay();
  },
  watch: {
    threshold: debounce(function () {
      this.updateSearchThreshold();
    }, 500),

    query() {
      this.searchResults = this.fuse.search(this.query).filter((result) => {
        return (
          (!result.item.meta.allowedRoles ||
            result.item.meta.allowedRoles.includes(this.profile.role)) &&
          (!result.item.meta.searchFilter ||
            result.item.meta.searchFilter(this)) &&
          (!result?.meta?.requiredAppAccess ||
            intersection(result?.meta?.requiredAppAccess, this.appAccesses)
              .length > 0)
        );
      });
    },
  },
  methods: {
    highlightSearch(result) {
      let term = this.query;
      return result.item.meta.title.replace(
        new RegExp(term, "gi"),
        (match) =>
          `<span class="font-bold text-${result.item.meta.product}-100">${match}</span>`
      );
    },
    getPassFlow(result) {
      return result.item.meta?.passFlowTo ?? null;
    },
    setFocus() {
      this.searchIsFocused = true;
      this.$refs.searchInput.focus();
      this.query = "";
    },
    getParams(result) {
      return result.item.meta?.pathProps
        ? result.item.meta.pathProps(this)
        : null;
    },
    checkTimeOfDay() {
      let time = moment().hour();

      if (time < 12) {
        this.greeting = "morning";
      } else if (time > 12 && time < 17) {
        this.greeting = "afternoon";
      } else {
        this.greeting = "evening";
      }
    },
    // For test purposes
    updateSearchThreshold() {
      const threshold = Number(this.threshold);
      this.fuse = new Fuse(this.allSearchableRoutes, { ...fuseOptions, threshold });
    }
  },
};
</script>

<style scoped>
.cms {
  background-color: rgb(216, 179, 22);
}

.dh {
  background-color: #e561a3;
}

.sreq {
  background-color: #b282ff;
}

.greeting {
  opacity: 1;
  transition: 0.1s;
  transition-delay: 0.05s;
}

.greeting.no-go {
  opacity: 0;
}

.search-label {
  @apply font-frank text-6xl font-500 absolute block;
  color: #ccc;
  transform-origin: 0 0;
  transition: 0.15s;
}
.search-label.move-it {
  transform: scale(0.6) translate(0, -7.25rem);
  color: #000;
}

.clear-search {
  @apply absolute right-0;
  top: 50%;
  transform: translateY(-50%);
}

</style>
